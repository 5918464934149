<template>
  <div class="wrapper forgot-password text-center">
    <div class="title-wrap relative">
      <h3 class="page-titles">
        Reset your password
      </h3>
      <p>Enter your user account's email address and we will send you a password reset link.</p>
    </div>

    <div class="w-1/2 mt-3 flex flex-col justify-center justify-items-center mr-auto ml-auto">
      <div class="p-8 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" v-if="sent">
        Reset email has been sent to your email address.
      </div>
      <div class="form-control">
        <label class="label">
          <span class="label-text text-lg text-primary-dark" />
        </label>
        <input
          :disabled="sent"
          v-model="email"
          type="email"
          :class="{'input-error': errorMsg}"
          placeholder="Email Address"
          class="py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
        >
      </div>
      <div class="w-full flex justify-center">
        <button
          @click="forgotPassword"
          :disabled="sent"
          class="btn-primary mt-8"
        >
          Send password reset email
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: '',
      errorMsg: false,
      sent: false
    }
  },
  methods: {
    async forgotPassword () {
      if (this.email === '') {
        this.errorMsg = true
        return
      }
      await this.$api.forgotPassword(this.email)

      this.sent = true
    }
  }
}
</script>

<style></style>
